<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title
        class="body-1"
        v-if="navigete != null && navigete != '' && navigete != 'null'"
      >
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        สร้างใบแจ้งหนี้อื่นๆ
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-receipt</v-icon
        ><span class="my-3 ml-3">ข้อมูลใบแจ้งหนี้</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0 pb-3">
          <v-col cols="12" md="3" lg="2" xl="1" class="pt-5">
            <span><b> เลขที่เอกสาร : </b></span>
          </v-col>
          <v-col
            cols="12"
            md="9"
            lg="4"
            xl="5"
            class="py-3 pl-0 pb-0"
            style="display: flex"
          >
            <v-text-field
              ref="invoiceno"
              :value="invoiceno"
              @change="(value) => (invoiceno = value)"
              autocomplete="off"
              placeholder="*สามารถเว้นว่างได้เพื่อให้ระบบออกเลขบิลให้อัตโนมัติ"
              required
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-0 pb-3">
          <v-col cols="12" md="3" lg="2" xl="1" class="pt-5">
            <span><b> วันที่ : </b></span>
          </v-col>
          <v-col
            cols="12"
            md="9"
            lg="4"
            xl="5"
            class="py-3 pl-0 pb-0"
            style="display: flex"
          >
            <v-menu
              ref="search_menu_invoice_date"
              v-model="search_menu_invoice_date"
              :close-on-content-click="false"
              :return-value.sync="invoice_date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="invoice_date"
                @input="
                  (search_menu_invoice_date = false),
                    $refs.search_menu_invoice_date.save(invoice_date)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-address-card</v-icon
        ><span class="my-3 ml-3">ที่อยู่ใบแจ้งหนี้สาขา</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="mx-0 pb-3">
          <v-col cols="12" md="3" lg="2" xl="1" class="pt-3">
            <span><b> ที่อยู่ใบแจ้งหนี้ : </b></span>
          </v-col>
          <v-col
            cols="12"
            md="9"
            lg="10"
            xl="11"
            class="py-3 pl-0 pb-0"
            style="display: flex"
          >
            <!-- <v-select ref="branch_address_invoice_select" v-model="branch_address_invoice_select" :items="branch_address_invoice_list" :item-text="item => item.fullAddress" outlined return-object hide-details ></v-select> -->

            <v-radio-group v-model="branch_address_invoice_select">
              <v-radio
                v-for="(item, index) in branch_address_invoice_list"
                :key="index"
                :label="item.fullAddress"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-address-card</v-icon
        ><span class="my-3 ml-3">ที่อยู่ใบแจ้งหนี้ร้านค้า</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="my-2">
          <v-col cols="12">
            <v-radio-group v-model="rd_address_shop" row>
              <v-radio label="ที่อยู่ร้านค้า" value="1"></v-radio>
              <v-radio label="กำหนดเอง" value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider class="mb-5" />
        <div v-if="rd_address_shop == '1'">
          <v-row class="mx-0 pb-3">
            <v-col cols="12" md="3" lg="2" xl="1" class="pt-5">
              <span><b> เลือกร้านค้า : </b></span>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="4"
              xl="5"
              class="py-3 pl-0 pb-0"
              style="display: flex"
            >
              <v-autocomplete
                v-model="shop_select"
                ref="refsshop_select"
                :items="shop_list"
                @change="select_shop(shop_select)"
                outlined
                item-text="name"
                item-value="id"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-0 pb-3">
            <v-col cols="12" md="3" lg="2" xl="1" class="pt-3">
              <span><b> ที่อยู่ใบแจ้งหนี้ : </b></span>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="10"
              xl="11"
              class="py-3 pl-0 pb-0"
              style="display: flex"
            >
              <v-radio-group
                v-if="shop_select != null"
                ref="shop_address_invoice_select"
                v-model="shop_address_invoice_select"
              >
                <v-radio
                  v-for="(item, index) in shop_address_invoice_list"
                  :key="index"
                  :label="item.fullAddress"
                  :value="item.id"
                ></v-radio>
              </v-radio-group>
              <span v-else-if="shop_select == null"> -- กรุณาเลือกร้านค้า -- </span>
              <span v-if="shop_address_invoice_list.length == 0 && shop_select != null">
                -- ไม่พบข้อมูล --
              </span>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <br />
          <v-row row>
            <v-col class="pb-2" cols="12" md="12">
              <v-row row>
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>ชื่อบุคคล/นิติบุคคล :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.Name"
                    @change="(value) => (ShopAddressInvoice.Name = value)"
                    autocomplete="off"
                    ref="refShopAddressInvoiceName"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col class="pb-2" cols="12" md="12">
              <v-row row>
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เลขผู้เสียภาษี :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.IDCardNumber"
                    @change="(value) => (ShopAddressInvoice.IDCardNumber = value)"
                    autocomplete="off"
                    ref="refShopAddressInvoiceIDCardNumber"
                    maxlength="20"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col class="pb-2" cols="12" md="12">
              <v-row row>
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เบอร์โทร :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.Phone"
                    @change="(value) => (ShopAddressInvoice.Phone = value)"
                    autocomplete="off"
                    ref="refShopAddressInvoicePhone"
                    maxlength="10"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col class="pb-2" cols="12" md="12">
              <v-row row>
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>ที่อยู่ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="ShopAddressInvoice.TextAddress"
                    @change="(value) => (ShopAddressInvoice.TextAddress = value)"
                    autocomplete="off"
                    ref="refShopAddressInvoiceTextAddress"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col class="pb-2" cols="12" md="12">
              <v-row row>
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>รหัสไปรษณีย์ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="ShopAddressInvoice.Address"
                    ref="refShopAddressInvoiceAddress"
                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    outlined
                    required
                    return-object
                    hide-details
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-btn dark color="success" @click="AddShopAddressInvoice('add')" class="mr-1"><v-icon left >mdi-content-save</v-icon> เพิ่มและบันทึก</v-btn> -->
        </div>
      </v-card-text>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon
        ><span class="my-3 ml-3">รายละเอียด</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="my-3">
          <v-col cols="12">
            <v-radio-group @change="calculateVAT()" v-model="radio_vat_type_select" row>
              <v-radio label="ไม่มีภาษี" value="1"></v-radio>
              <v-radio label="รวมภาษี" value="2"></v-radio>
              <v-radio label="ไม่รวมภาษี" value="3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0 packhai-border-table packhai-table"
              :headers="[
                {
                  text: '',
                  value: 'delete',
                  align: 'center',
                  sortable: false,
                  width: '10px',
                },
                {
                  text: 'ลำดับ',
                  value: 'num',
                  align: 'center',
                  sortable: false,
                  width: '10px',
                },
                {
                  text: 'รายละเอียด',
                  value: 'description',
                  align: 'left',
                  sortable: false,
                  width: '300px',
                },
                {
                  text: 'จำนวน',
                  value: 'quantity',
                  align: 'right',
                  sortable: false,
                  width: '100px',
                },
                {
                  text: 'ราคา',
                  value: 'price',
                  align: 'right',
                  sortable: false,
                  width: '100px',
                },
                {
                  text: 'ราคารวม',
                  value: 'total_price',
                  align: 'right',
                  sortable: false,
                  width: '100px',
                },
              ]"
              :items="bill_for_created_list"
              hide-default-footer
              :items-per-page="10000"
              :mobile-breakpoint="0"
            >
              <template v-slot:item.delete="{ item }">
                <v-btn
                  v-if="bill_for_created_list.length > 1"
                  icon
                  color="danger"
                  @click="delete_item(item)"
                  ><v-icon>fa-trash-alt</v-icon></v-btn
                >
                <v-btn v-else icon><v-icon>fa-trash-alt</v-icon></v-btn>
              </template>
              <template v-slot:item.num="{ item }">
                {{ bill_for_created_list.indexOf(item) + 1 }}
              </template>

              <template v-slot:item.description="{ item }">
                <v-text-field
                  ref="descriptionInput"
                  :value="item.description"
                  @change="(value) => (item.description = value)"
                  autocomplete="off"
                  placeholder="รายละเอียด"
                  required
                  outlined
                  hide-details
                  @blur="descriptionInput(item)"
                ></v-text-field>
              </template>
              <template v-slot:item.quantity="{ item }">
                <!-- {{ formatMoney(item.quantity) }} -->
                <v-text-field
                  ref="quantityInput"
                  :value="item.quantity"
                  @change="(value) => (item.quantity = value)"
                  autocomplete="off"
                  placeholder="จำนวน"
                  required
                  outlined
                  type="number"
                  hide-details
                  @blur="quantityInput(item)"
                ></v-text-field>
              </template>
              <template v-slot:item.price="{ item }">
                <!-- {{ formatMoney(item.price) }} -->
                <v-text-field
                  ref="priceInput"
                  :value="item.price"
                  @change="(value) => (item.price = value)"
                  autocomplete="off"
                  placeholder="ราคา"
                  type="number"
                  required
                  outlined
                  hide-details
                  @blur="priceInput(item)"
                ></v-text-field>
              </template>

              <template v-slot:item.total_price="{ item }">
                {{ formatMoney(item.total_price) }}
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="my-2">
            <v-btn color="info" outlined @click="add_data_bill()">
              <v-icon left> fa-plus </v-icon> เพิ่มแถว
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col lg="5">
            <v-divider></v-divider>
            <v-row class="py-3">
              <v-col> ยอดรวม </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ formatMoney(this.sum_price.toFixed(2)) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-3">
              <v-col> ยอดที่คำนวนภาษี </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ formatMoney(this.sum_price_vat.toFixed(2)) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-3">
              <v-col>
                ภาษีมูลค่าเพิ่ม
                <span v-if="radio_vat_type_select == '1'">(No VAT)</span>
                <span v-if="radio_vat_type_select == '2'">(VAT Included)</span>
                <span v-if="radio_vat_type_select == '3'">(VAT Excluded)</span>
              </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ formatMoney(this.sum_vat.toFixed(2)) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-3">
              <v-col> ยอดรวมทั้งหมด </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ formatMoney(this.sum_all.toFixed(2)) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn @click="created_invoine()" color="info">สร้างใบแจ้งหนี้</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import {
  reportService_dotnet,
  shopService_dotnet,
  generalService_dotnet,
} from "@/website/global";
import { AlertSuccess } from "@/website/global_alert";
import {
  FormatInvoice,
  FormatOrder,
  formatMoney,
  isNumberWNoDot,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,

    navigete: [],

    shop_select: null,
    shop_list: [],

    shop_address_invoice_list: [],
    shop_address_invoice_select: null,

    branch_address_invoice_list: [],
    branch_address_invoice_select: null,

    radio_vat_type_select: "3",

    // created bill
    dialog_created_bill: false,
    bill_description: null,
    bill_quantity: null,
    bill_price: null,
    bill_for_created_list: [],

    // sum
    sum_price: 0,
    sum_price_vat: 0,
    sum_vat: 0,
    sum_all: 0,

    // invoicd data
    invoiceno: null,
    search_menu_invoice_date: false,
    invoice_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    rd_address_shop: "1",
    ShopAddressInvoice: {
      ID: null,
      Name: null,
      Phone: null,
      IDCardNumber: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      IsDefault: true,
    },
    postcode_mapping: [],
  }),
  async created() {
    this.navigete = JSON.parse(localStorage.getItem("navigete"));
    await this.add_data_bill();
    await this.get_branch_invoice_address();
    await this.get_shop_all();
    await this.get_postcode_mapping();
    this.page_loading = false;
  },
  computed: {
    computed_search_date_from() {
      return this.formatDates(this.invoice_date);
    },
  },
  watch: {
    rd_address_shop: function (val) {
      if (val == "2") {
        this.shop_select = null;
      }
    },
  },
  methods: {
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),

    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    async get_shop_all() {
      let res1 = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        { branchId: localStorage.getItem("Branch_BranchID") },
        { headers: this.header_token }
      );
      this.shop_list = res1.data.result;
      this.shop_list = [{ id: null, name: " -- กรุณาเลือกร้านค้า -- " }];
      for (var i = 0; i < res1.data.result.length; i++) {
        if (!res1.data.result[i].shop.isDelete) {
          this.shop_list.push(res1.data.result[i].shop);
        }
      }
    },

    async get_branch_invoice_address() {
      let res1 = await axios.post(
        reportService_dotnet + "Invoice/get-branch-address-invoice-list",
        { branchID: localStorage.getItem("Branch_BranchID") },
        { headers: this.header_token }
      );
      if (res1.data.length > 0) {
        this.branch_address_invoice_select = res1.data[0].id;
        this.branch_address_invoice_list = res1.data;
      } else {
        this.branch_address_invoice_select = null;
        this.branch_address_invoice_list = [];
      }
    },

    open_create_bill() {
      this.dialog_created_bill = true;
      this.bill_description = null;
      this.bill_quantity = null;
      this.bill_price = null;
    },

    add_data_bill() {
      var data_dict = {
        description: this.bill_description,
        quantity: this.bill_quantity,
        price: this.bill_price,
        total_price: 0,
      };
      this.bill_for_created_list.push(data_dict);
      this.bill_description = null;
      this.bill_quantity = null;
      this.bill_price = null;
    },

    descriptionInput(item) {
      if (item.description == null || item.description == "") {
        this.$refs.descriptionInput.focus();
        return;
      }
    },

    quantityInput(item) {
      if (item.quantity == null || item.quantity == 0) {
        this.$refs.quantityInput.focus();
        return;
      }

      if (item.quantity != null && item.quantity > 0 && item.price != null) {
        // && item.price > 0
        item.total_price = item.quantity * item.price;
      }
      this.calculateVAT();
    },

    priceInput(item) {
      if (item.price == null) {
        // || item.price == 0
        this.$refs.priceInput.focus();
        return;
      }

      if (item.quantity != null && item.quantity > 0 && item.price != null) {
        // && item.price > 0
        item.total_price = item.quantity * item.price;
      }
      this.calculateVAT();
    },

    calculateVAT() {
      this.sum_price = 0;
      this.sum_price_vat = 0;
      this.sum_vat = 0;
      this.sum_all = 0;

      if (this.bill_for_created_list.length > 0) {
        for (var i = 0; i < this.bill_for_created_list.length; i++) {
          if (this.bill_for_created_list[i].total_price != null) {
            this.sum_price += this.bill_for_created_list[i].total_price;
          }
        }

        if (this.radio_vat_type_select == "1") {
          this.sum_all = this.sum_price;
          this.sum_price_vat = this.sum_price;
        } else if (this.radio_vat_type_select == "2") {
          this.sum_vat = (this.sum_price * 7) / 107;
          this.sum_price_vat = this.sum_price - this.sum_vat;

          this.sum_all = this.sum_price;
        } else if (this.radio_vat_type_select == "3") {
          this.sum_vat = (this.sum_price * 7) / 100;
          this.sum_price_vat = this.sum_price;
          this.sum_all = this.sum_vat + this.sum_price;
        }
      }
    },

    async select_shop(item) {
      if (item != null) {
        let res1 = await axios.post(
          shopService_dotnet + "ShopAddressInvoice/get-shop-address-invoice-list",
          { shopID: item, orderBy: 2 },
          { headers: this.header_token }
        );
        if (res1.data.length > 0) {
          this.shop_address_invoice_select = res1.data[0].id;
          this.shop_address_invoice_list = res1.data;
        } else {
          this.shop_address_invoice_select = null;
          this.shop_address_invoice_list = [];
        }
      } else {
        this.shop_address_invoice_select = null;
        this.shop_address_invoice_list = [];
      }
    },

    edit_date_format_for_dotnet(val) {
      const _date = new Date(val);
      var informDatetime_tmp = new Date(
        _date.getTime() + 7 * (60 * 60 * 1000)
      ).toISOString();
      return informDatetime_tmp;
    },

    async created_invoine() {
      if (this.rd_address_shop == "1") {
        if (this.shop_address_invoice_select == null) {
          this.$refs.refsshop_select.focus();
          this.$refs.refsshop_select.activateMenu();
          return;
        }
      }

      // set ลำดับ
      for (var it = 0; it < this.bill_for_created_list.length; it++) {
        this.bill_for_created_list[it]["order"] = it + 1;
      }

      // description
      for (var i1 = 0; i1 < this.bill_for_created_list.length; i1++) {
        if (
          this.bill_for_created_list[i1].description == null ||
          this.bill_for_created_list[i1].description == null
        ) {
          this.$refs.descriptionInput.focus();
          return;
        }
      }

      // quantity
      for (var i2 = 0; i2 < this.bill_for_created_list.length; i2++) {
        if (
          this.bill_for_created_list[i2].quantity == null ||
          this.bill_for_created_list[i2].quantity <= 0
        ) {
          this.$refs.quantityInput.focus();
          return;
        }
      }

      // price
      for (var i3 = 0; i3 < this.bill_for_created_list.length; i3++) {
        if (this.bill_for_created_list[i3].price == null) {
          // || this.bill_for_created_list[i3].price <= 0
          this.$refs.priceInput.focus();
          return;
        }
      }
      if (this.rd_address_shop == "2") {
        await this.AddShopAddressInvoice("add");
      }

      var param_tmp = {
        invoiceNo: this.invoiceno, // can null
        branchID: localStorage.getItem("Branch_BranchID"),
        shopID: this.shop_select,
        createdBy: localStorage.getItem("Branch_StaffID"),
        branchAddressInvoiceID: this.branch_address_invoice_select,
        shopAddressInvoiceID: this.shop_address_invoice_select,
        isSelfGenerate: true,
        invoiceGroupID: null,
        totalItemPrice: this.sum_price,
        vatType: this.radio_vat_type_select,
        vatAmount: this.sum_vat,
        calculatedPrice: this.sum_price_vat,
        totalPrice: this.sum_all,
        invoiceDate: this.edit_date_format_for_dotnet(
          new Date(this.invoice_date + " 00:00:00").toISOString()
        ),
      };

      var data_item_tmp = [];

      for (var i = 0; i < this.bill_for_created_list.length; i++) {
        var dt = this.bill_for_created_list[i];
        data_item_tmp.push({
          id: 0,
          invoiceID: 0,
          description: dt.description,
          quantity: dt.quantity,
          unitPrice: dt.price,
          order: dt.order,
        });
      }
      try {
        this.loading = true;
        let res1 = await axios.post(
          reportService_dotnet + "Invoice/create-bill-invoice",
          {
            data_invoice: param_tmp,
            data_item: data_item_tmp,
          },
          { headers: this.header_token }
        );
        if (res1.status == 200) {
          this.AlertSuccess();
          this.clearDataCreate();
        }
        this.rd_address_shop = "1";
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.AlertError();
      }
    },

    clearDataCreate() {
      this.sum_price = 0;
      this.sum_price_vat = 0;
      this.sum_vat = 0;
      this.sum_all = 0;
      this.bill_for_created_list = [];

      this.invoiceno = null; // ivoiceNo
      this.invoice_date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10); // invoiceDate
      this.branch_address_invoice_select = null; // branchAddressInvoiceId
      this.shop_select = null; // shopId
      this.shop_address_invoice_select = null; // shopAddressInvoiceId
      this.radio_vat_type_select = "3"; // vatType

      this.add_data_bill();
      this.get_branch_invoice_address();
      this.get_shop_all();
    },

    delete_item(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        // title: 'ยกเลิกใบแจ้งหนี้',
        text: "ต้องการลบ " + item.description + " หรือไม่ !!",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.bill_for_created_list.splice(this.bill_for_created_list.indexOf(item), 1);
        }
      });
    },

    async get_postcode_mapping() {
      let response = await axios.post(
        generalService_dotnet + "General/get-postcodemapping",
        {},
        { headers: this.header_token }
      );
      this.postcode_mapping = response.data;
    },

    async AddShopAddressInvoice(type) {
      if (type == "add") {
        if (this.ShopAddressInvoice.Name == null || this.ShopAddressInvoice.Name == "") {
          this.$refs.refShopAddressInvoiceName.focus();
          return;
        } else if (
          this.ShopAddressInvoice.Phone == null ||
          this.ShopAddressInvoice.Phone == ""
        ) {
          this.$refs.refShopAddressInvoicePhone.focus();
          return;
        } else if (
          this.ShopAddressInvoice.TextAddress == null ||
          this.ShopAddressInvoice.TextAddress == ""
        ) {
          this.$refs.refShopAddressInvoiceTextAddress.focus();
          return;
        } else if (
          this.ShopAddressInvoice.Address == null ||
          this.ShopAddressInvoice.Address == ""
        ) {
          this.$refs.refShopAddressInvoiceAddress.focus();
          return;
        }

        this.loading = true;
        let res2 = await axios.post(
          shopService_dotnet + "ShopAddressInvoice/update-shop-address-invoice",
          {
            id: 0,
            shopId: 0,
            name: this.ShopAddressInvoice.Name,
            idcardNumber: this.ShopAddressInvoice.IDCardNumber,
            phone: this.ShopAddressInvoice.Phone,
            address: this.ShopAddressInvoice.TextAddress,
            postcodeMappingId: this.ShopAddressInvoice.Address.Id,
            isDefault: false,
            isDeleted: false,
            shopLogo: null,
          },
          { headers: this.header_token }
        );

        var last_insert_id;
        for (var item of res2.data) {
          if (item.isDefault == true) {
            last_insert_id = item.id;
            break;
          }
        }

        this.shop_address_invoice_select = last_insert_id;
        this.shop_select = 0;

        this.ShopAddressInvoice = {
          ID: null,
          Name: null,
          Phone: null,
          IDCardNumber: null,
          TextAddress: null,
          Address: null,
          PostcodeMappingID: null,
          IsDefault: true,
        };

        this.loading = false;
      }
    },

    FormatInvoice,
    FormatOrder,
    formatMoney,
    isNumberWNoDot,
    AlertSuccess,
  },
};
</script>

<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.mobile-none {
  display: none;
}
.pc-none {
  display: none;
}
@media (max-width: 599px) {
  .mobile-none {
    display: none;
  }
  .pc-none {
    display: ;
  }
}
</style>
